import styled from 'styled-components'

export const WorkspaceBrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 30px;
  min-height: 400px;
`

export const WorkspaceBrandBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WorkspaceBrandActions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;

  & > button {
    margin: 0;
  }
`

export const WorkspaceBrandField = styled.div`
  display: flex;
  flex-direction: column;
`

export const WorkspaceBrandInputWrapper = styled.div`
  display: flex;
  gap: 8px;

  & > *:first-child {
    flex: 1;
  }
`

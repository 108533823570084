import { gql } from 'apollo-boost'

export const GET_BRAND_OPTIONS = gql`
  query GET_BRAND_OPTIONS {
    listBrandSOAOnCategoryPaginated {
      data {
        id
        brand {
          id
          name
          brandColor
        }
        category {
          id
          name
        }
        language
      }
      totalCount
      cursor
    }
  }
`

export const SET_BRAND_OPTION = gql`
  mutation SET_BRAND_OPTION(
    $brandSOAOnCategoryId: ID!
    $workspaceId: ID!
    $brandId: ID!
    $brandColor: String!
  ) {
    createWorkspaceBrandSOAOnCategory(
      input: {
        brandSOAOnCategoryId: $brandSOAOnCategoryId
        workspaceId: $workspaceId
      }
    ) {
      id
      brand {
        id
        name
      }
    }
    createBrandColor(input: { brandId: $brandId, brandColor: $brandColor }) {
      message
      success
    }
  }
`

import styled from 'styled-components'

export const ColorPickInputWrapper = styled.div`
  position: relative;
`

export const ColorPickInputButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e2e5e9;
  width: 112px;
  height: 38px;

  font-size: 14px;
  font-weight: 500;
  color: #17092f;

  &:hover {
    background-color: #f9f9f9;
  }
`

export const ColorPickInputDot = styled.div`
  width: 38px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  background-color: ${({ color }) => color};
  border-right: 1px solid #e2e5e9;
`

export const ColorPickInputLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 6px;
`

export const ColorPickInputPopover = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 1;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

import { useEffect, useState } from 'react'

export function useClickOutside(ref) {
  const [isClickOutside, setClickOutside] = useState(true)

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      return setClickOutside(true)
    }
    return setClickOutside(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return { isClickOutside, setClickOutside }
}

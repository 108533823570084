import React from 'react'
import classNames from 'classnames'

import oldColors from './modules/oldColors.module.scss'
import oldSpacing from './modules/oldSpacing.module.scss'
import oldTypography from './modules/oldTypography.module.scss'

import borders from './modules/borders.module.scss'
import breakpoints from './modules/breakpoints.module.scss'
import colors from './modules/colors.module.scss'
import elevations from './modules/elevations.module.scss'
import spacing from './modules/spacing.module.scss'
import typography from './modules/typography.module.scss'

export type ThemeProviderProps = React.HTMLAttributes<HTMLDivElement>

export function ThemeProvider(props: ThemeProviderProps) {
  return (
    <div
      {...props}
      className={classNames(
        'ThemeProvider',

        // old style guide, don't use these
        oldColors.colors,
        oldSpacing.spacing,
        oldTypography.typography,

        // current style guide, use these
        borders.borders,
        breakpoints.breakpoints,
        colors.colors,
        elevations.elevations,
        spacing.spacing,
        typography.typography,

        props.className
      )}
    />
  )
}

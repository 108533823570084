import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button } from '@winnin/insights_ui'
import { GET_BRAND_OPTIONS, SET_BRAND_OPTION } from './query'
import { Form, Label, Title } from '../../styled'
import { Alert } from '../../../Alert'
import Select from '../../../Select'
import Input from '../../../Input'
import { useDebounce, useSaveTerritoryToWorkspace } from '../../../../hooks'
import { useToastStore } from '../../../../stores/ToastStore'
import { getClassName } from '../../../../utils/getClassName'
import { ColorPickInput } from '../../../ColorPickInput'

import * as S from './styles'

export default function WorkspaceBrand({ company, onRequestClose }) {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    return company?.category
      ? {
          label: company?.category?.name,
          value: company?.category?.name,
        }
      : null
  })
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [territoryURL, setTerritoryURL] = useState('')

  const { dispatch } = useToastStore()
  const {
    isFetchingTerritory,
    isSavingTerritory,
    isTerritoryValid,
    isTerritoryLinkInvalid,
    isTerritoryVideoCountInsufficient,
    isTerritoryNotBelongingToWorkspace,
    selectedTerritory,
    saveTerritoryToWorkspace,
    checkTerritoryUrlIsValid,
    validateTerritory,
    resetTerritoryStatus,
  } = useSaveTerritoryToWorkspace()

  const { data, loading } = useQuery(GET_BRAND_OPTIONS, {
    onCompleted: (data) => {
      const brandList = Array.from(
        data?.listBrandSOAOnCategoryPaginated?.data || []
      )

      const currentBrand = brandList.find((item) => {
        return item?.brand?.name === company?.brandSOA
      })

      if (currentBrand) {
        setSelectedBrand({
          label: currentBrand?.brand?.name,
          value: currentBrand?.id,
          brandId: currentBrand?.brand?.id,
          color: currentBrand?.brand?.brandColor,
        })
      }
    },
  })

  const [setBrandOption, { loading: isSavingBrand }] = useMutation(
    SET_BRAND_OPTION,
    {
      onError: (error) => {
        console.error('WorkspaceBrand @ set >>>>>', error)
        dispatch({
          type: 'create',
          message: 'Oops! An error occurred. Please, try again.',
        })
      },
    }
  )

  const availableCategories = useMemo(() => {
    const brandsCategories = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    if (!brandsCategories.length) {
      return []
    }
    const dedupedCategories = brandsCategories.reduce((prev, curr) => {
      const category = curr?.category?.name
      if (prev.includes(category)) {
        return prev
      }
      return [...prev, category]
    }, [])
    const formattedOptions = dedupedCategories.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
    return formattedOptions
  }, [data])

  const availableBrands = useMemo(() => {
    if (!selectedCategory) {
      return []
    }

    const brandsData = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    const brandsWithCategory = brandsData.reduce((prev, curr) => {
      const currentCategoryName = curr?.category?.name
      const selectedCategoryName = selectedCategory?.value
      if (currentCategoryName === selectedCategoryName) {
        return [...prev, curr]
      }
      return prev
    }, [])

    const formattedOptions = brandsWithCategory.map((item) => {
      return {
        label: item?.brand?.name,
        value: item?.id,
        color: item?.brand?.brandColor,
        brandId: item?.brand?.id,
      }
    })

    return formattedOptions
  }, [selectedCategory, data])

  const handleValidateTerritoryUrl = async (territoryUrl) => {
    const urlIsValid = await checkTerritoryUrlIsValid(territoryUrl)

    if (!urlIsValid) {
      return
    }

    const workspaceId = company?.panels?.[0]?.id
    validateTerritory(territoryUrl, workspaceId)
  }

  const { debouncedAction: debounceHandleValidateTerritoryUrl } = useDebounce(
    handleValidateTerritoryUrl
  )

  const handleTerritoryURLChange = (event) => {
    const url = event.target.value

    setTerritoryURL(url)

    if (!url) {
      resetTerritoryStatus()
      return
    }

    debounceHandleValidateTerritoryUrl(url)
  }

  const hasError =
    (isTerritoryNotBelongingToWorkspace || isTerritoryLinkInvalid) &&
    territoryURL
  const hasWarning = isTerritoryVideoCountInsufficient && territoryURL
  const canSubmitBrand = selectedBrand && selectedCategory
  const canSubmitTerritory = territoryURL && isTerritoryValid
  const canSubmit =
    (canSubmitBrand && !territoryURL) ||
    (canSubmitBrand && canSubmitTerritory) ||
    (canSubmitTerritory && !selectedBrand && !selectedCategory)

  const handleSubmit = async () => {
    try {
      const workspaceId = company?.panels?.[0]?.id

      if (!workspaceId || !canSubmit) {
        return
      }

      if (selectedBrand?.value && selectedCategory) {
        await setBrandOption({
          variables: {
            brandSOAOnCategoryId: selectedBrand?.value,
            workspaceId,
            brandId: selectedBrand?.brandId,
            brandColor: selectedBrand?.color,
          },
        })
      }

      if (isTerritoryValid && territoryURL) {
        await saveTerritoryToWorkspace(territoryURL, workspaceId)
      }

      onRequestClose()
      dispatch({ type: 'create', message: 'Brand successfully configured' })
    } catch (error) {
      console.error('WorkspaceBrand @ submit >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    }
  }

  return (
    <S.WorkspaceBrandContainer>
      <S.WorkspaceBrandBody>
        <div>
          <Title>Core category and Brand:</Title>
          <Form>
            <div>
              <Label>Core category</Label>
              <Select
                value={selectedCategory}
                placeholder="Select category"
                options={availableCategories}
                onChange={(option) => {
                  setSelectedCategory(option)
                  setSelectedBrand(null)
                }}
                isDisabled={loading}
              />
            </div>
            <S.WorkspaceBrandField>
              <Label>Brand</Label>
              <S.WorkspaceBrandInputWrapper>
                <Select
                  value={selectedBrand}
                  placeholder="Select Brand"
                  options={availableBrands}
                  onChange={(option) => {
                    setSelectedBrand(option)
                  }}
                  isDisabled={!selectedCategory || loading}
                />
                {selectedBrand && (
                  <ColorPickInput
                    title={`Change color of ${selectedBrand?.label}`}
                    color={selectedBrand?.color}
                    onChangeColor={(color) => {
                      setSelectedBrand((prev) => ({
                        ...prev,
                        color,
                      }))
                    }}
                  />
                )}
              </S.WorkspaceBrandInputWrapper>
            </S.WorkspaceBrandField>
          </Form>
        </div>

        <div>
          <Title>Expanded category on home page:</Title>

          <S.WorkspaceBrandField>
            <Label>Add the URL of a topic, subtopic, or owned topic</Label>

            <Input
              name="teritory-url"
              value={territoryURL}
              className={getClassName({
                error: hasError,
                warning: hasWarning,
              })}
              onChange={handleTerritoryURLChange}
            />

            {territoryURL && (
              <>
                {isFetchingTerritory && (
                  <Alert status="loading" title="Buscando tópico..." />
                )}
                {selectedTerritory && (
                  <Alert
                    status="info"
                    title={`Tópico selecionado: ${selectedTerritory?.name}`}
                  />
                )}
                {!selectedTerritory && isTerritoryValid && (
                  <Alert
                    status="success"
                    title="Este tópico pertence a este workspace e tem 100+ vídeos nos últimos 7 dias"
                  />
                )}
                {isTerritoryVideoCountInsufficient && (
                  <Alert
                    status="warning"
                    title="Favor selecionar outro: este tópico pertence a este workspace, mas não tem 100+ vídeos nos últimos 7 dias"
                  />
                )}
                {isTerritoryNotBelongingToWorkspace && (
                  <Alert
                    status="error"
                    title="Favor selecionar outro: este tópico customizado não pertence a este workspace"
                  />
                )}
                {isTerritoryLinkInvalid && (
                  <Alert
                    status="error"
                    title="Favor selecionar outro: este link é inválido"
                  />
                )}
              </>
            )}
          </S.WorkspaceBrandField>
        </div>
      </S.WorkspaceBrandBody>

      <S.WorkspaceBrandActions>
        <Button appearance="outline" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!canSubmit || isSavingTerritory || isSavingBrand}
        >
          Save
        </Button>
      </S.WorkspaceBrandActions>
    </S.WorkspaceBrandContainer>
  )
}

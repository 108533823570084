import React, { useEffect, useRef, useState } from 'react'
import { useClickOutside } from '@winnin/winnin_ui.hooks.use-click-outside'
import { SketchPicker } from 'react-color'

import * as S from './styles'

export const ColorPickInput = ({ color, onChangeColor, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentColor, setCurrentColor] = useState(color || '#000000')

  const ref = useRef(null)

  const { isClickOutside } = useClickOutside(ref)

  const handleToggleColorPicker = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    if (isClickOutside) {
      setIsOpen(false)
    }
  }, [isClickOutside])

  useEffect(() => {
    setCurrentColor(color)
  }, [color])

  return (
    <S.ColorPickInputWrapper ref={ref}>
      <S.ColorPickInputButton
        onClick={handleToggleColorPicker}
        disabled={isOpen}
        {...props}
      >
        <S.ColorPickInputDot color={currentColor} />
        <S.ColorPickInputLabel>{currentColor}</S.ColorPickInputLabel>
      </S.ColorPickInputButton>

      <S.ColorPickInputPopover isOpen={isOpen}>
        <SketchPicker
          color={currentColor}
          onChangeComplete={({ hex }) => onChangeColor(hex)}
          onChange={({ hex }) => setCurrentColor(hex)}
          disableAlpha
        />
      </S.ColorPickInputPopover>
    </S.ColorPickInputWrapper>
  )
}
